body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
}

.blue-btn {
  width: 100%;
  max-width: 220px;
  display: flex;
  position: relative;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #1d6bb5;
  border: none;
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px 15px;
  outline: 0;
}

.advantages-slider {
  .slick-next {
    right: -15px;
    &:before {
      content: url("../../assets/img/nav-right.png");
    }
  }

  .slick-prev {
    left: -15px;
    &:before {
      content: url("../../assets/img/nav-left.png");
    }
  }
}


.blue-bg {
  background-color: #0a1c2e;
}

.slick-initialized .slick-slide {
  outline: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    outline: none;
    color: inherit;
  }
}

p {
  margin: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-60 {
  margin-right: 60px;
}


.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-60 {
  margin-left: 60px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.minw-100 {
  min-width: 100px;
}

.mtop-20 {
  margin-top: 20px;
}

.mtop-50 {
  margin-top: 50px;
}

.color-white {
  color: #fff;
  h2, span {
    color: #fff !important;
  }

}

.navbar-brand {
  a {
    font-size: 0 !important;
  }
}

.navbar-brand.current-menu-item {
  border-bottom: 0 !important;
}

.advantages-slider__wrapper__item__info  p {

    color: #fff !important;

}

.our-works__content__wrapper__item img {
  width: 100% !important;
  height: 240px !important;
  object-fit: cover !important;
}

#order-value {
  pointer-events: none;
  border-bottom: none;
}